<template>
	<div class="container">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
			<el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '账户列表') }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="top">
			<el-input v-model="searchData.value"
				:placeholder="fun.getLanguageText($store.state.language, '请输入关键词进行搜索')"></el-input>
			<el-button icon="el-icon-search" type="primary" @click="getUserList">
				{{ fun.getLanguageText($store.state.language, '搜索') }}
			</el-button>
			<el-select v-model="searchData.accountType"
				:placeholder="fun.getLanguageText($store.state.language, '请选择账号类型')" @change="accountTypeChange">
				<el-option :label="fun.getLanguageText($store.state.language, '全部类型')" value="0"></el-option>
				<el-option v-for="item in roleList" :key="item.id"
					:label="fun.getLanguageText($store.state.language, item.alias)" :value="item.id"></el-option>
			</el-select>
			<el-button v-if="$store.state.roleId<4" class="add-username" icon="el-icon-plus" type="primary"
				@click="add">{{ fun.getLanguageText($store.state.language, '添加账号') }}
			</el-button>
		</div>
		<el-table :data="tableData" :max-height="$store.state.clientHeight" stripe style="width: 100%">
			<el-table-column :label="fun.getLanguageText($store.state.language, '序号')" prop="index" width="120">
			</el-table-column>
			<el-table-column :label="fun.getLanguageText($store.state.language, '账号名称')" prop="name" width="250">
			</el-table-column>
			<el-table-column :label="fun.getLanguageText($store.state.language, '邮箱')" prop="email">
			</el-table-column>
			<el-table-column :label="fun.getLanguageText($store.state.language, '账号类型')" prop="role">
        <template slot-scope="scope">
          <span>{{ fun.getLanguageText($store.state.language, scope.row.role) }}</span>
        </template>
			</el-table-column>
			<el-table-column :label="fun.getLanguageText($store.state.language, '公司名称')" prop="companyName">
			</el-table-column>
			<el-table-column :label="fun.getLanguageText($store.state.language, '创建日期')" prop="created_at">
			</el-table-column>
			<el-table-column fixed="right" :label="fun.getLanguageText($store.state.language, '操作')" width="100">
				<template slot-scope="scope">
					<el-button :disabled="!($store.state.roleId<4) || scope.row.edit === true" size="small" type="text"
						@click="create(scope)"> {{ fun.getLanguageText($store.state.language, '编辑') }}
					</el-button>
					<el-button :disabled="!($store.state.roleId<4)" size="small" type="text" @click="del(scope)">
						{{ fun.getLanguageText($store.state.language, '删除') }}
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination :current-page="pageData.currentPage" :page-size="pageData.pageSize"
			:page-sizes="pageData.pageSizes" :total="pageData.total" background class="footer"
			layout="sizes, prev, pager, next, jumper, total" @size-change="sizeChange" @current-change="currentChange">
		</el-pagination>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				router: false,
				input: '',
				user_type: '',
				tableData: [],
				searchData: { // 搜索数据
					value: '', // 查询内容
					accountType: ""
				},
				pageData: {
					total: 0, // 总条数
					currentPage: 1, // 当前页数
					totalPage: 0, // 总页数
					pageSize: 20, //
					pageSizes: [10, 20, 30, 50]
				},
				roleList: []
			}
		},
		created() {
			this.getUserList()
			this.getRoleList()
		},
		methods: {
			// 更新
			accountTypeChange(e) {
				this.getUserList()
			},
			// 编辑任务
			create(e) {
				this.$router.push({
					name: "Home/UserNameList/AddUserNameList",
					query: {
						id: e.row.id
					}
				})
				console.log(e)
			},
			// 读取用户类型列表
			getRoleList() {
				this.$http.post('role/list', {}).then(res => {
					if (res.data.msg == 'success') {
						this.roleList = res.data.data
					}
				}).catch(err => {
					// this.getRoleList()
				})
			},
			// 改变页码
			currentChange(e) {
				this.pageData.currentPage = e
				this.getUserList()
			},
			// 选择页码
			sizeChange(e) {
				this.pageData.pageSize = e
				this.getUserList()
			},
			// 读取用户列表
			getUserList() {
				this.$http.post('user/list', {
					search: this.searchData.value,
					accountType: this.searchData.accountType,
					currentPage: this.pageData.currentPage,
					pageSize: this.pageData.pageSize
				}).then(res => {
					console.log(res.data, this.$store.state.userData)
					if (res.data.msg === 'success') {
						res.data.data.info.map((item, index) => {
							if (item.name === localStorage.getItem('username')) {
								item.edit = true
							}
							item.index = index + 1
						})
						this.tableData = res.data.data.info
						this.pageData.total = Number(res.data.data.page.total)
					}
				}).catch(err => {

				})
			},
			// 添加账号
			add() {
				// this.$router.push({name:"Home/UserNameList/AddUserNameList",query:{alert:"页面跳转成功"}})
				this.$router.push('/Home/UserNameList/AddUserNameList')
			},
			// 删除指定账号
			del(e) {

				this.$confirm(this.fun.getLanguageText(this.$store.state.language, '此操作将永久删除该数据, 是否继续?'),
						this.fun.getLanguageText(this.$store.state.language, '提示'), {
						confirmButtonText: this.fun.getLanguageText(this.$store.state.language, '确定'),
						cancelButtonText: this.fun.getLanguageText(this.$store.state.language, '取消'),
						type: 'warning'
					}).then((res) => {
					// 执行删除操作
					return this.$http.post('user/delete', {
						id: e.row.id
					})
				}).then(res => {
					return new Promise((a, b) => {
						if (res.data.msg === 'success') {
							this.tableData.splice(e.$index, 1);
							this.$message({
								type: 'success',
								message: this.fun.getLanguageText(this.$store.state.language,
									'删除成功!')
							});
						} else {
							b(res.data.msg)
						}
					})
				}).catch((err) => {
					console.log(err)
					if (err == 'cancel') {
						err = "取消删除"
					}
					this.$message({
						type: 'info',
						message: this.fun.getLanguageText(this.$store.state.language, err)
					});
				});
			},
			// 计算高度
			getListHeight() {
				let Html = document.getElementsByClassName('box-card')
				if (Html.length > 0) {
					this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60
					console.log(this.$store.state.clientHeight, Html[0].clientHeight)
				}
			}
		},

		mounted() {
			this.getListHeight()
			window.onresize = () => {
				return (() => {
					this.getListHeight()
				})();
			}
		},
		destroyed() {
			window.onresize = null;
		}
	};
</script>

<style lang="less" scoped>
	.el-input {
		width: 240px;
		margin-right: 10px;
	}

	.el-select {
		width: 180px;
		margin-left: 20px;
	}

	.container {
		display: flex;
		flex-direction: column;
	}

	.top {
		// height: 32px;
		margin-bottom: 20px;
		position: relative;
	}

	.add-username {
		position: absolute;
		right: 0;
	}

	.footer {
		display: flex;
		padding: 0;
		margin-top: 14px;
		justify-content: flex-end;
	}
</style>
